<template>
    <button
        v-if="!href"
        :class="{
            'size-small': size,
            'color-blue': color === 'blue',
            'color-orange': color === 'orange',
            'icon': icon
        }"
        class="AppButton"
    >
        <slot></slot>
    </button>
    <a
        v-else
        :href="href"
        :class="{
            'size-small': size,
            'color-blue': color === 'blue',
            'color-orange': color === 'orange',
            'icon': icon
        }"
        class="AppButton"
    >
        <slot></slot>
    </a>
</template>

<script>
export default {
    props: ["href", "size", "color", "icon"]
};
</script>

<style scoped>
.AppButton {
    @apply bg-dilo-black text-dilo-white shadow-md;

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 0 1rem;
    max-width: 10rem;
    line-height: 1;
    transition: all 0.2s;
    font-weight: bold;
    cursor: pointer;
    position: relative;
}

.AppButton.color-blue {
    @apply bg-dilo-blue bg-opacity-60;
}

.AppButton:hover {
    @apply bg-dilo-orange;
}

.AppButton.color-orange {
    @apply bg-dilo-orange;
}

.AppButton.color-orange:hover {
    @apply bg-dilo-green;
}

.AppButton.size-small {
    max-width: none;
    display: inline-flex;
    padding: 0 0.5rem;
    height: 2.25rem;
    font-size: 0.875rem;
}

.AppButton.icon {
    padding-left: 2.5rem;
}

.AppButton >>> svg {
    position: absolute;
    left: .5rem;
    top: 50%;
    transform: translateY(-50%);
}
</style>
