// define class
export class Remote {
  constructor(language) {
    // define endpoint
    this.pimcoreUrl = "https://pim.dilo.eu/pimcore-graphql-webservices/operating-manuals?apikey=e5a335e5271beceec40c68eb78b42d75"
    // this.pimcoreUrl = "https://update.pim.dilo.eu.entwicklerpark.de/pimcore-graphql-webservices/operating-manuals?apikey=e5a335e5271beceec40c68eb78b42d75"
    // this.pimcoreUrl = "https://pimdilo2.entwicklerpark.de/pimcore-graphql-webservices/operating-manuals?apikey=e5a335e5271beceec40c68eb78b42d75"
    // this.pimcoreUrl = "https://pimstage.entwicklerpark.de/pimcore-graphql-webservices/operating-manuals?apikey=e5a335e5271beceec40c68eb78b42d75"
    this.language = language
  }

  setLanguage(language) {
    this.language = language
  }

  // default query
  async queryData(query) {
    const response = await fetch(this.pimcoreUrl, {
      method: "POST",
      headers: {
        // we use text/plain.
        // application/json will break the request because of CORS.
        // datahub does not properly handle CORS preflight requests.
        "Content-Type": "text/plain",
        Accept: "application/json",
      },
      body: JSON.stringify({ query }),
    })
    const json = await response.json()
    return json.data
  }

  // query groups
  async fetchAreas() {
    const query = `
      {
        getProductGroupListing(published: true) {
          edges {
            node {
              id,
              name(language: "${this.language}")
            }
          }
        }
      }
    `
    const jsonData = await this.queryData(query)
    const result = jsonData.getProductGroupListing.edges.map((item) => { return item.node })
    // const filtered = result.filter(item => item.publishToManualDiloEu !== false)
    return result
  }

  // query groups
  async fetchGroups() {
    const query = `
      {
        getModelGroupListing(published: true) {
          edges {
            node {
              id,
              name(language: "${this.language}"),
              publishToManualDiloEu
            }
          }
        }
      }
    `
    const jsonData = await this.queryData(query)
    const result = jsonData.getModelGroupListing.edges.map((item) => { return item.node })
    const filtered = result.filter(item => item.publishToManualDiloEu !== false)
    return filtered
  }

  // query lines
  async fetchLines() {
    const query = `
      {
        getModelLineListing(published: true) {
          edges {
            node {
              id,
              name(language: "${this.language}"),
              publishToManualDiloEu
            }
          }
        }
      }
    `
    const jsonData = await this.queryData(query)
    const result = jsonData.getModelLineListing.edges.map((item) => { return item.node })
    const filtered = result.filter(item => item.publishToManualDiloEu !== false)
    return filtered
  }

  // query models
  async fetchModels() {
    const query = `
    {
      getModelListing(published: true) {
        edges {
          node {
            id
            name(language: "${this.language}")
            version
            productGroup {
              ... on object_productGroup {
                id
                name(language: "${this.language}")
              }
            }
            modelGroup {
              ... on object_modelGroup {
                id
                name(language: "${this.language}")
              }
            }
            modelLine {
              ... on object_modelLine {
                id
                name(language: "${this.language}")
              }
            }
            operatingManuals {
              ... on fieldcollection_operatingManual {
                lang
                operatingManual {
                  ... on asset {
                    id
                    filename
                  }
                }
              }
            }
          }
        }
      }
    }

    `
    const jsonData = await this.queryData(query)
    return jsonData.getModelListing.edges.map((item) => { return item.node })
  }
}
