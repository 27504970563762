<template>
    <div id="ResultListing" class="py-4">
        <h2
            class="leading-none flex flex-wrap items-end justify-between border-b-2 border-white pb-4"
        >
            <b class="mr-4">{{ $t("resultlisting.title") }}</b>
            <small class="text-sm">
                {{ populatedResultListing.length }}
                {{ $t("resultlisting.count-label") }}
            </small>
        </h2>

        <!-- Result Table -->
        <table
            v-if="populatedResultListing && populatedResultListing.length > 0"
        >
            <tr>
                <th>{{ $t("resultlisting.table-heading-1") }}</th>
                <th>{{ $t("resultlisting.table-heading-2") }}</th>
                <th>{{ $t("resultlisting.table-heading-3") }}</th>
                <th>{{ $t("resultlisting.table-heading-4") }}</th>
            </tr>
            <tr v-for="model in activePageResultListing" :key="model.id">
                <td>
                    <b class="text-dilo-black">
                        {{ model.name }}
                    </b>
                    <!-- <br />
                    <span class="text-dilo-black">{{ model.modelLine.name }}</span> -->
                </td>
                <td>{{ model.version || "-" }}</td>

                <!-- Lang dropdown -->
                <td>
                    <div
                        v-if="model._selectedOperatingManual !== null"
                        class="language-list"
                        :class="{
                            'is-open': openLanguageDropdown === model.id
                        }"
                        @click="toggleDropdown(model.id)"
                        @focusout="handleFocusOut"
                        tabindex="0"
                    >
                        <figure class="flex items-center justify-between">
                            <img
                                v-show="model._selectedOperatingManual.lang"
                                :src="
                                    'flags/' +
                                        model._selectedOperatingManual.lang +
                                        '.svg'
                                "
                                :alt="model._selectedOperatingManual.lang"
                                :title="model._selectedOperatingManual.lang"
                                height="20"
                                width="28"
                            />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                        </figure>
                        <ul>
                            <li
                                v-for="om in model.operatingManuals"
                                :key="om.lang"
                                @click.stop="selectOperatingManual(om, model)"
                                v-show="
                                    model._selectedOperatingManual.lang !==
                                        om.lang
                                "
                            >
                                <img
                                    v-show="om.lang"
                                    :src="'flags/' + om.lang + '.svg'"
                                    :alt="om.lang"
                                    :title="om.lang"
                                    height="20"
                                    width="28"
                                />
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        -
                    </div>
                </td>

                <!-- Request Button -->
                <td>
                    <AppButton
                        v-if="model._selectedOperatingManual"
                        color="blue"
                        size="small"
                        icon="svg"
                        class="w-full"
                        @click.prevent="requestFile(model)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 flex-shrink-0"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                            />
                        </svg>
                        <span v-if="model._selectedOperatingManual.operatingManual !== null" class="ml-1">
                            {{ $t("resultlisting.requestbutton-label") }}
                        </span>
                        <span v-else>
                            {{ $t("resultlisting.requestbutton-filerequest") }}
                        </span>
                    </AppButton>
                    <p v-else class="text-sm leading-tight">
                        {{ $t("resultlisting.requestbutton-missing") }}
                    </p>
                </td>
            </tr>
        </table>

        <!-- Filter no match -->
        <p v-else class="py-4 flex items-center text-dilo-orange">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
            <span class="ml-2">{{ $t("resultlisting.noresult-text") }}</span>
        </p>

        <!-- Pagination -->
        <ul class="pagination" v-show="totalPagesCount > 1">
            <li
                :class="{ 'is-disabled': activePage === 1 }"
                @click="goToPage(activePage - 1)"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 19l-7-7 7-7"
                    />
                </svg>
            </li>
            <li
                v-for="page in totalPagesCount"
                :key="page"
                @click="goToPage(page)"
                :class="{
                    'is-active': activePage == page,
                    'is-hidden': hidePaginationButton(page)
                }"
            >
                {{ hidePaginationButton(page) ? "..." : page }}
            </li>
            <li
                :class="{ 'is-disabled': activePage === totalPagesCount }"
                @click="goToPage(activePage + 1)"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 5l7 7-7 7"
                    />
                </svg>
            </li>
        </ul>
    </div>
</template>

<script>
import AppButton from "./template/AppButton.vue";

export default {
    name: "ResultListing",
    emits: ["onRequestFile"],
    props: ["filteredResult"],
    data() {
        return {
            itemsPerPage: 10,
            activePage: 1,
            openLanguageDropdown: ""
        };
    },
    computed: {
        populatedResultListing() {
            // add additional data to array of entries
            return this.filteredResult.map(entry => {
                if (entry.operatingManuals !== null)
                    entry["_selectedOperatingManual"] =
                        entry.operatingManuals[0];
                else entry["_selectedOperatingManual"] = null
                return entry;
            });
        },
        totalPagesCount() {
            return Math.ceil(
                this.populatedResultListing.length / this.itemsPerPage
            );
        },
        activePageResultListing() {
            // visible entries for pagination
            return this.populatedResultListing.filter((model, i) => {
                const index = i + 1;
                const offset = (this.activePage - 1) * this.itemsPerPage + 1;
                if (index >= offset && index < offset + this.itemsPerPage)
                    return true;
            });
        }
    },
    methods: {
        goToPage(page) {
            this.activePage = page;
        },
        selectOperatingManual(om, model) {
            model._selectedOperatingManual = om;
            this.closeDropdown();
        },
        requestFile(model) {
            this.$emit("onRequestFile", {
                om: model._selectedOperatingManual,
                model
            });
        },
        hidePaginationButton(page) {
            const lastPage = this.totalPagesCount;
            const firstPage = 1;
            const hideSomeButtons = this.totalPagesCount > 8;
            const nearActivePage =
                page >= this.activePage - 2 && page <= this.activePage + 2;
            if (
                (hideSomeButtons && nearActivePage) ||
                page === lastPage ||
                page === firstPage
            ) {
                return false;
            } else {
                return true;
            }
        },
        toggleDropdown(id) {
            if (this.openLanguageDropdown !== id) {
                this.openLanguageDropdown = id;
            } else {
                this.closeDropdown();
            }
        },
        closeDropdown() {
            this.openLanguageDropdown = "";
        },
        handleFocusOut() {
            this.closeDropdown()
        },
        formatDate(unix_timestamp) {
            const date = new Date(unix_timestamp * 1000);
            return (
                date.getDate() +
                "." +
                (date.getMonth() + 1) +
                "." +
                date.getFullYear()
            );
        }
    },
    watch: {
        filteredResult() {
            this.goToPage(1);
        },
        activePageResultListing(newListing) {
            if (newListing.length === 1) { // only if we have a single result
                const query = {} // parse query params
                window.location.search.substr(1).split("&").forEach(function(item) {query[item.split("=")[0]] = item.split("=")[1]})
                const desiredLanguage = query.language
                if (query.language) { // only if parameter is provided
                    const model = newListing[0]
                    const operatingManuals = model.operatingManuals
                    const byLanguage = (lang) => lang === desiredLanguage
                    const desiredOperatingManual = operatingManuals.find(manual => byLanguage(manual.lang))
                    if (typeof desiredOperatingManual !== 'undefined')
                        this.selectOperatingManual(desiredOperatingManual, model)
                }
            }
        }
    },
    components: { AppButton }
};
</script>

<style scoped>
table {
    @apply table-auto w-full;

    border-spacing: 0 1rem;
    border-collapse: separate;
}

tr:not(:first-child) {
    @apply shadow-md;
}

th:nth-child(2),
td:nth-child(2) {
    display: none;
}

@screen md {
    th:nth-child(2),
    td:nth-child(2) {
        display: table-cell;
    }
}

th {
    @apply px-2 py-0 text-sm text-center;
}

@screen md {
    th {
        @apply px-4;
    }
}

th:nth-child(1) {
    @apply text-left;

    width: auto;
    word-wrap: break-word;
    hyphens: auto;
}

th:nth-child(2) {
    width: 6.5rem;
}

th:nth-child(3) {
    width: 6.5rem;
}

th:nth-child(4) {
    width: 11.5rem;
}

td {
    @apply px-2 py-2 bg-white leading-tight text-center;
}

@screen md {
    td {
        @apply px-4 py-4;
    }
}

td:first-child {
    @apply text-left;
}

tr:hover td {
    @apply bg-dilo-gray-light bg-opacity-75;
}

/* Pagination */

.pagination {
    @apply flex flex-wrap justify-center mt-4;
}

.pagination li {
    @apply mx-2 bg-dilo-white shadow-md h-7 w-7 flex text-center justify-center items-center my-1 pt-1 text-sm cursor-pointer transition-colors;
}

.pagination li:not(:first-child):not(:last-child):hover {
    @apply bg-dilo-gray-light text-white;
}

.pagination li:first-child,
.pagination li:last-child {
    @apply bg-transparent shadow-none mx-0 pt-0;
}

.pagination li.is-disabled {
    @apply pointer-events-none opacity-30;
}

.pagination li.is-active {
    @apply bg-dilo-gray-dark text-white pointer-events-none;
}

.pagination li.is-hidden + li.is-hidden {
    @apply hidden;
}

/* Language List */

.language-list {
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.language-list.is-open {
    z-index: 2;
}

.language-list figure {
    @apply bg-dilo-white px-2 py-2 relative;
}

.language-list figure img {
    @apply shadow-md;
}

.language-list.is-open figure {
    z-index: 2;
}

.language-list ul {
    @apply shadow-md bg-dilo-white;

    overflow: hidden;
    max-height: 0;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 100%;
    transition: all 0.33s;
}

.language-list.is-open ul {
    @apply py-1;

    overflow: auto;
    max-height: 16.25rem;
}

.language-list ul li {
    @apply py-1 px-2 transition-colors;

    cursor: pointer;
}

.language-list ul li:hover {
    @apply bg-dilo-gray-light;
}

.language-list ul li img {
    @apply shadow-md;
}

.language-list.is-open svg {
    transform: rotate(180deg);
}
</style>
