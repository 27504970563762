<template>
    <div id="FilterResult">
        <SelectOption
            :name="$t('filterresult.productarea-label')"
            :id="'area'"
            :visibleOptions="visibleOptions.areas"
            :resetSelectionOnce="resetSelectionOnce"
            @onSelectOptionChange="onSelectOptionChange($event)"
            @resetSelectionOnceDone="this.resetSelectionOnce = false"
            class="mt-4 mb-8"
        />
        <SelectOption
            :name="$t('filterresult.productgroup-label')"
            :id="'productgroup'"
            :visibleOptions="visibleOptions.groups"
            :resetSelectionOnce="resetSelectionOnce"
            @onSelectOptionChange="onSelectOptionChange($event)"
            @resetSelectionOnceDone="this.resetSelectionOnce = false"
            class="mt-4 mb-8"
        />
        <SelectOption
            :name="$t('filterresult.productline-label')"
            :id="'productline'"
            :visibleOptions="visibleOptions.lines"
            :resetSelectionOnce="resetSelectionOnce"
            @onSelectOptionChange="onSelectOptionChange($event)"
            @resetSelectionOnceDone="this.resetSelectionOnce = false"
            class="mt-4 mb-8"
        />
        <SelectOption
            :name="$t('filterresult.model-label')"
            :id="'model'"
            :visibleOptions="visibleOptions.models"
            :resetSelectionOnce="resetSelectionOnce"
            :preselectedOptionId="preselectedModelId"
            @onSelectOptionChange="onSelectOptionChange($event)"
            @resetSelectionOnceDone="this.resetSelectionOnce = false"
            class="mt-4 mb-8"
        />

        <p class="text-center">
            <a
                href="#clear"
                @click.prevent="clearSelections()"
                class="font-bold underline"
            >
                {{ $t("filterresult.resetfilter-label") }}
            </a>
        </p>
    </div>
</template>

<script>
import SelectOption from "./SelectOption.vue";

export default {
    name: "FilterResult",
    emits: ["onSelectOptionChange", "clearSelections"],
    props: ["visibleOptions"],
    data() {
        return {
            resetSelectionOnce: false,
            preselectedModelId: null
        };
    },
    computed: {},
    methods: {
        onSelectOptionChange(event) {
            this.$emit("onSelectOptionChange", event);
        },
        clearSelections() {
            this.$emit("clearSelections");
            this.resetSelectionOnce = true
        }
    },
    created() {
        // preselect model value
        const query = {} // parse query params
        window.location.search.substr(1).split("&").forEach(function(item) {query[item.split("=")[0]] = item.split("=")[1]})
        this.preselectedModelId = query.model
    },
    components: {
        SelectOption
    }
};
</script>
