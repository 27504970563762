<template>
    <div id="AppHeader">
        <div class="shadow-md">
            <div class="container h-32 flex justify-between items-center">
                <a
                    href="/"
                >
                    <img alt="Dilo Logo" src="../../assets/logo.png" width="95" height="95" />
                </a>

                <div class="w-full max-w-xs pl-32">
                    <a
                        href="https://dilo.eu"
                        target="_blank"
                        class="flex items-center justify-end"
                    >
                        <span class="mr-1">dilo.eu</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                        </svg>
                    </a>

                    <div class="border-b-2 border-dilo-black my-1"></div>

                    <LocaleChanger class="text-right" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LocaleChanger from "../../components/LocaleChanger.vue";

export default {
    components: {
        LocaleChanger
    }
};
</script>
