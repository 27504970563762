<template>
    <div id="RequestForm" class="">
        <p class="my-4">
            {{ $t("requestform.intro-text-1") }}<br />
            {{ $t("requestform.intro-text-2") }}
        </p>

        <div class="shadow-md bg-dilo-gray bg-opacity-40 px-5 pt-4 pb-3 mb-2">
            <div class="mr-2">{{ $t("requestform.selection-label") }}:</div>
            <div class="flex flex-wrap items-center">
                <h2 class="text-2xl text-dilo-black font-bold mr-4">
                    {{ selectedFile.shortLabel }}
                </h2>
                <img
                    :src="'flags/' + selectedFile.lang + '.svg'"
                    :alt="selectedFile.lang"
                    :title="selectedFile.lang"
                    height="20"
                    width="28"
                    class="shadow-md mr-6"
                />
                <a
                    href="#clearSelectedFile"
                    @click.prevent="clearSelectedFile()"
                    class="underline"
                >
                    {{ $t("requestform.selection-clear") }}
                </a>
            </div>
        </div>

        <form
            v-show="!submitSuccessful"
            @submit.prevent="onSubmit($event)"
            class="pt-4"
            autocomplete="false"
            role="form"
            enctype="multipart/form-data"
        >
            <p class="italic text-right">
                * = {{ $t("requestform.mandatory") }}
            </p>

            <fieldset
                class="flex flex-wrap"
                :class="{
                    'is-loading': loading,
                    'is-success': submitSuccessful
                }"
            >
                <div class="w-full lg:w-5/12">
                    <div class="field-group">
                        <label>{{ $t("requestform.label-salutation") }}*</label>
                        <select name="mauticform[salutation]" value="" required>
                            <option value="" selected>
                                - {{ $t("requestform.placeholder-select") }} -
                            </option>
                            <option value="mr">
                                {{ $t("requestform.option-salutation-mr") }}
                            </option>
                            <option value="mrs">
                                {{ $t("requestform.option-salutation-mrs") }}
                            </option>
                        </select>
                    </div>

                    <div class="field-group">
                        <label>{{ $t("requestform.label-firstname") }}*</label>
                        <input
                            name="mauticform[firstname]"
                            type="text"
                            value=""
                            required
                        />
                    </div>

                    <div class="field-group">
                        <label>{{ $t("requestform.label-lastname") }}*</label>
                        <input
                            name="mauticform[lastname]"
                            type="text"
                            value=""
                            required
                        />
                    </div>

                    <div class="field-group">
                        <label>{{ $t("requestform.label-email") }}*</label>
                        <input
                            name="mauticform[email]"
                            type="email"
                            value=""
                            required
                        />
                    </div>

                    <div class="field-group">
                        <label>{{ $t("requestform.label-country") }}*</label>
                        <select name="mauticform[country]" value="" required>
                            <option value="" selected>
                                - {{ $t("requestform.placeholder-select") }} -
                            </option>
                            <option
                                v-for="country in countries"
                                :key="country.name"
                                :value="country.value"
                            >
                                {{ country.name }}
                            </option>
                        </select>
                    </div>

                    <div class="field-group" v-show="selectedFileNoPdfExists">
                        <label>{{ $t("requestform.label-phone") }}</label>
                        <input
                            name="mauticform[phone]"
                            type="tel"
                            value=""
                        />
                    </div>
                </div>

                <div class="w-full lg:w-1/12"></div>

                <div class="w-full lg:w-6/12 max-w-lg">
                    <input
                        value="no"
                        name="mauticform[newsletter]"
                        type="hidden"
                    />
                    <label>
                        <input
                            name="mauticform[newsletter][]"
                            type="checkbox"
                            value="yes"
                        />
                        <i></i>
                        <span>
                            &nbsp;{{ $t("requestform.label-newsletter") }}
                        </span>
                    </label>

                    <input
                        value="no"
                        name="mauticform[privacy_policy]"
                        type="hidden"
                    />
                    <label>
                        <input
                            name="mauticform[privacy_policy][]"
                            type="checkbox"
                            value="yes"
                            required
                        />
                        <i></i>
                        <span>
                            &nbsp;* {{ $t("requestform.label-privacy") }}
                        </span>
                    </label>
                </div>

                <input
                    name="mauticform[fileid]"
                    type="hidden"
                    :value="selectedFile.id + '/' + selectedFile.name"
                />
                <input
                    name="mauticform[filelabel]"
                    type="hidden"
                    :value="selectedFile.label"
                />
                <input
                    name="mauticform[language]"
                    type="hidden"
                    :value="$i18n.locale"
                />
            </fieldset>

            <AppButton
                type="submit"
                name="mauticform[submit]"
                value=""
                color="orange"
                class="mt-4 ml-auto"
                :class="{ 'is-loading': loading }"
            >
                <span v-if="!loading">
                    {{ $t("requestform.button-label") }}
                </span>
                <span v-else>{{ $t("requestform.button-loading") }}...</span>
            </AppButton>

            <input type="hidden" name="mauticform[formId]" value="1" />
            <input
                type="hidden"
                name="mauticform[return]"
                :value="formConfig.url + '/s/forms/preview/' + formConfig.id"
            />
            <input
                type="hidden"
                name="mauticform[formName]"
                value="requestoperatingmanualdownloadlink"
            />
            <input type="hidden" name="mauticform[messenger]" value="1" />
        </form>

        <p
            class="bg-dilo-green text-white leading-tight text-lg px-4 py-3 font-bold mt-4"
            v-if="submitSuccessful"
        >
            {{ $t("requestform.success-text-1") }}<br />
            {{ $t("requestform.success-text-2") }}
        </p>
    </div>
</template>

<script>
import countries from "../helper/countries.json";
import AppButton from "./template/AppButton.vue";

export default {
    name: "RequestForm",
    props: ["selectedEntry", "submitSuccessful"],
    emits: ["submitSuccessful", "onClearSelectedFile"],
    data() {
        return {
            loading: false,
            formConfig: {
                method: "post",
                url: "https://hub.dilo.eu",
                id: 1,
                filePrefix: "https://pim.dilo.eu"
            }
        };
    },
    computed: {
        selectedFileNoPdfExists() {
            // if there is no pdf assigned in pimcore to this entry
            return this.selectedEntry.om.operatingManual === null ? true : false
        },
        selectedFile() {
            let obj;
            // case if the selected operating maunual has no file provided for a language
            if (this.selectedFileNoPdfExists) {
                obj = {
                    id: null,
                    name: null,
                    lang: this.selectedEntry.om.lang,
                    shortLabel: this.selectedEntry.model.name,
                    label:
                        this.selectedEntry.model.name
                        + " / " +
                        this.selectedEntry.model.modelLine.name
                };
            } else { // default case with selected file existing
                obj = {
                    id: this.selectedEntry.om.operatingManual.id,
                    name: this.selectedEntry.om.operatingManual.filename,
                    lang: this.selectedEntry.om.lang,
                    shortLabel: this.selectedEntry.model.name,
                    label:
                        this.selectedEntry.model.name
                        + " / " +
                        this.selectedEntry.model.modelLine.name
                };
            }
            return obj;
        },
        countries() {
            return countries[this.$i18n.locale];
        },
        formSubmitUrl() {
            return (
                this.formConfig.url +
                "/form/submit?formId=" +
                this.formConfig.id
            );
        },
        formReturnUrl() {
            return this.formConfig.url + "/s/forms/preview/" + this.formConfig.id;
        },
        validEmail() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test("this.formData.email");
        }
    },
    methods: {
        clearSelectedFile() {
            this.$emit("onClearSelectedFile");
        },
        async onSubmit(event) {
            this.loading = true;

            const formData = new FormData(event.target);
            console.log(...formData);

            const response = await fetch(this.formSubmitUrl, {
                method: this.formConfig.method,
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                },
                // mode: 'no-cors', // no-cors, *cors, same-origin
                // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin', // include, *same-origin, omit
                // redirect: 'follow', // manual, *follow, error
                // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: formData
            });
            this.loading = false;

            const result = await response.text();
            console.log(result);

            this.$emit("submitSuccessful", true);
        }
    },
    components: { AppButton }
};
</script>

<style scoped>
fieldset.is-loading {
    @apply opacity-50 pointer-events-none;
}

fieldset.is-success {
    @apply hidden pointer-events-none;
}

@screen lg {
    fieldset .field-group {
        @apply flex items-center mb-4;
    }
}

label {
    @apply block mb-1 mt-4 font-bold leading-tight relative;
}

@screen lg {
    fieldset .field-group label {
        @apply w-40 mb-0 mt-0;
    }
}

label span {
    @apply font-normal;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
    @apply appearance-none border border-transparent w-full py-3 px-4 bg-white placeholder-gray-400 shadow-md text-base focus:outline-none focus:ring-2 focus:ring-dilo-orange focus:border-transparent;
}

input[type="checkbox"] {
    @apply mr-1;
}

input[type="checkbox"] + i {
    @apply left-0 top-0 absolute h-4 w-4 bg-dilo-white shadow-md;
}

input[type="checkbox"]:checked + i {
    @apply bg-dilo-orange;
}

select {
    @apply appearance-none border border-transparent w-full py-3 px-3 bg-white placeholder-gray-400 shadow-md text-base;
}

>>> button.is-loading {
    @apply pointer-events-none opacity-50;
}
</style>
