export default {
  "de": {
    "header": {
      "meta-title": "Bedienungsanleitungen"
    },
    "footer": {
      "company-title": "DILO",
      "company-subtitle": "Armaturen und Anlagen GmbH",
      "company-street": "Frundsbergstrasse 36",
      "company-city": "D-87727 Babenhausen/Bayern ",
      "tel-label": "Tel",
      "tel-number": "+49 (0) 83 33 - 302-0",
      "mail-label": "E-Mail",
      "mail-address-account": "info",
      "mail-address-domain": "dilo.com",
      "imprint-label": "Impressum",
      "imprint-link": "https://dilo.eu/impressum",
      "terms-label": "AGB",
      "terms-link": "https://dilo.eu/agb",
      "privacy-label": "Datenschutz",
      "privacy-link": "https://dilo.eu/datenschutz",
      "web-label": "dilo.eu",
      "web-link": "https://dilo.eu",
      "copyright": "© DILO GmbH"
    },
    "app": {
      "intro-title": "Download Center – Bedienungsanleitungen",
      "intro-subtitle": "Finden Sie die passende Bedienungsanleitung für Ihr Produkt.",
      "intro-text-1": "Mit unserer Online-Suche finden Sie Ihre Bedienungsanleitung schnell und einfach.",
      "intro-text-2": "Klicken Sie dann einfach auf Anfragen neben dem entsprechenden Dokument.",
      "contact-title": "Kontakt",
      "contact-subtitle": "Sie können Ihr Dokument nicht finden oder Sie haben weitere Fragen?",
      "contact-text": "Wenden Sie sich einfach direkt an uns.",
      "contact-button-label": "Kontakt",
      "contact-button-link": "https://dilo.eu/kontakt/kontaktformular"
    },
    "manualfinder": {
      "title-filter": "Suchfilter",
      "title-listing": "Bedienungsanleitungen",
      "title-form": "Bedienungsanleitung anfordern",
      "text-loading": "Lade"
    },
    "filterresult": {
      "productarea-label": "Produktbereich",
      "productgroup-label": "Produktgruppe",
      "productline-label": "Produktlinie",
      "model-label": "Baureihe",
      "resetfilter-label": "Suchfilter zurücksetzen"
    },
    "selectoption": {
      "count-label": "Einträge",
      "placeholder": "Bitte wählen",
      "placeholder-search": "Eingabe zum Suchen"
    },
    "resultlisting": {
      "title": "Einträge",
      "count-label": "Ergebnisse",
      "table-heading-1": "Baureihe / Produkt",
      "table-heading-2": "Version",
      "table-heading-3": "Sprache",
      "table-heading-4": "Anfrage",
      "requestbutton-label": "Download anfordern",
      "requestbutton-filerequest": "Auf Anfrage",
      "requestbutton-missing": "nicht verfügbar",
      "noresult-text": "Zu Ihrer Auswahl gibt es keine Dokumente."
    },
    "requestform": {
      "intro-text-1": "Fast geschafft. Füllen Sie einfach das Antragsformular aus und klicken Sie auf Datei anfordern.",
      "intro-text-2": "Wir senden Ihnen in wenigen Minuten eine E-Mail mit dem Download-Link zu Ihrem Dokument.",
      "selection-label": "Ihre Auswahl",
      "selection-clear": "Auswahl ändern",
      "mandatory": "Pflichtfelder",
      "label-salutation": "Anrede",
      "option-salutation-mr": "Herr",
      "option-salutation-mrs": "Frau",
      "label-firstname": "Vorname",
      "label-lastname": "Nachname",
      "label-email": "E-Mail",
      "label-country": "Land",
      "label-phone": "Telefon",
      "label-newsletter": "Ja, ich wünsche zukünftig den Newsletter der DILO Armaturen und Anlagen GmbH per E-Mail. Mir ist bewusst, dass ich jederzeit mein Einverständnis hierzu widerrufen kann.",
      "label-privacy": "Ich bin damit einverstanden, dass die DILO Armaturen und Anlagen GmbH meine Angaben vertraulich behandelt und sie zur Übersendung von Produktinformationen speichert und verarbeitet.",
      "placeholder-select": "Bitte wählen",
      "button-label": "Datei anfordern",
      "button-loading": "Anfrage wird gesendet",
      "success-text-1": "Ihre Daten wurden erfolgreich übermittelt.",
      "success-text-2": "Sie erhalten in kürze eine E-Mail."
    }
  },
  "en": {
    "header": {
      "meta-title": "Operating manuals"
    },
    "footer": {
      "company-title": "DILO",
      "company-subtitle": "Armaturen und Anlagen GmbH",
      "company-street": "Frundsbergstrasse 36",
      "company-city": "87727 Babenhausen/Bavaria",
      "tel-label": "Phone",
      "tel-number": "+49 (0) 83 33 - 302-0",
      "mail-label": "E-mail",
      "mail-address-account": "info",
      "mail-address-domain": "dilo.com",
      "imprint-label": "Legal notice",
      "imprint-link": "https://dilo.eu/en/imprint",
      "terms-label": "GTC",
      "terms-link": "https://dilo.eu/en/gtc",
      "privacy-label": "Data privacy",
      "privacy-link": "https://dilo.eu/en/data-privacy",
      "web-label": "dilo.eu",
      "web-link": "https://dilo.eu",
      "copyright": "© DILO GmbH"
    },
    "app": {
      "intro-title": "Download Center – Operating manuals",
      "intro-subtitle": "Find the appropriate operating manual for your DILO product.",
      "intro-text-1": "With our online search function you can easily find the requested operating manual.",
      "intro-text-2": "Simply click on \"Request\" next to the relevant document.",
      "contact-title": "Contact",
      "contact-subtitle": "You cannot find the requested document or you have further questions?",
      "contact-text": "Just contact us directly.",
      "contact-button-label": "Contact",
      "contact-button-link": "https://dilo.eu/en/contact/contact-form"
    },
    "manualfinder": {
      "title-filter": "Search filter",
      "title-listing": "Operating manuals",
      "title-form": "Request operating manual",
      "text-loading": "Loading"
    },
    "filterresult": {
      "productarea-label": "Product range",
      "productgroup-label": "Product group",
      "productline-label": "Product line",
      "model-label": "Series",
      "resetfilter-label": "Reset search filter"
    },
    "selectoption": {
      "count-label": "Entries",
      "placeholder": "Please select",
      "placeholder-search": "Search input"
    },
    "resultlisting": {
      "title": "Entries",
      "count-label": "Results",
      "table-heading-1": "Series / Product",
      "table-heading-2": "Version",
      "table-heading-3": "Language",
      "table-heading-4": "Request",
      "requestbutton-label": "Request download",
      "requestbutton-filerequest": "On request",
      "requestbutton-missing": "not available",
      "noresult-text": "There are no documents for your selection."
    },
    "requestform": {
      "intro-text-1": "Nearly finished. Simply fill in the application form and click on \"Request file\".",
      "intro-text-2": "You will receive an e-mail with the corresponding download link in a few minutes.",
      "selection-label": "Your selection",
      "selection-clear": "Change selection",
      "mandatory": "Mandatory fields",
      "label-salutation": "Title",
      "option-salutation-mr": "Mr.",
      "option-salutation-mrs": "Mrs.",
      "label-firstname": "First name",
      "label-lastname": "Second name",
      "label-email": "E-mail",
      "label-country": "Country",
      "label-phone": "Phone",
      "label-newsletter": "Yes, I would like to receive the DILO Armaturen und Anlagen GmbH newsletter by e-mail in the future. I am aware that I can revoke my consent to this at any time.",
      "label-privacy": "I agree that DILO Armaturen und Anlagen GmbH treat my information confidentially and store and process it for the purpose of sending product information.",
      "placeholder-select": "Please select",
      "button-label": "Request file",
      "button-loading": "Request is being sent",
      "success-text-1": "Your data have been transferred successfully",
      "success-text-2": "You will receive an e-mail shortly."
    }
  },
  "fr": {
    "header": {
      "meta-title": "Instructions de service"
    },
    "footer": {
      "company-title": "DILO",
      "company-subtitle": "Armaturen und Anlagen GmbH",
      "company-street": "Frundsbergstrasse 36",
      "company-city": "87727 Babenhausen/Bavière",
      "tel-label": "Téléphone",
      "tel-number": "+49 (0) 83 33 - 302-0",
      "mail-label": "E-mail",
      "mail-address-account": "info",
      "mail-address-domain": "dilo.com",
      "imprint-label": "Mentions légales",
      "imprint-link": "https://dilo.eu/fr/mentions-legales",
      "terms-label": "CGV",
      "terms-link": "https://dilo.eu/fr/cgv",
      "privacy-label": "Protection des données",
      "privacy-link": "https://dilo.eu/fr/protection-des-donnees",
      "web-label": "dilo.eu",
      "web-link": "https://dilo.eu",
      "copyright": "© DILO GmbH"
    },
    "app": {
      "intro-title": "Download Center – Instructions de service",
      "intro-subtitle": "Trouvez l'instruction de service correspondante pour votre produit DILO.",
      "intro-text-1": "Grâce à notre recherche en ligne, vous pouvez trouver l'instruction de service demandée facilement.",
      "intro-text-2": "Cliquez simplement sur \"Demande\" à côté du document concerné.",
      "contact-title": "Contact",
      "contact-subtitle": "Vous ne trouvez pas le document demandé ou vous avez d'autres questions ?",
      "contact-text": "Contactez-nous directement.",
      "contact-button-label": "Contact",
      "contact-button-link": "https://dilo.eu/fr/kontakt/formulaire-de-contact"
    },
    "manualfinder": {
      "title-filter": "Filtre de recherche",
      "title-listing": "Instructions de service",
      "title-form": "Demander l'instruction de service",
      "text-loading": "Charger"
    },
    "filterresult": {
      "productarea-label": "Domaine de produits",
      "productgroup-label": "Groupe de produits",
      "productline-label": "Ligne de produits",
      "model-label": "Série",
      "resetfilter-label": "Réinitialiser le filtre de recherche"
    },
    "selectoption": {
      "count-label": "Entrées",
      "placeholder": "Veuillez sélectionner",
      "placeholder-search": "Entrée pour la recherche"
    },
    "resultlisting": {
      "title": "Entrées",
      "count-label": "Résultats",
      "table-heading-1": "Série / Produit",
      "table-heading-2": "Version",
      "table-heading-3": "Langue",
      "table-heading-4": "Demande",
      "requestbutton-label": "Demande de téléchargement",
      "requestbutton-filerequest": "Sur demande",
      "requestbutton-missing": "non disponible",
      "noresult-text": "Il n'y a pas de documents pour votre sélection."
    },
    "requestform": {
      "intro-text-1": "Presque fini. Remplissez simplement le formulaire de demande et cliquez sur \"Demander un fichier\".",
      "intro-text-2": "Vous recevrez un e-mail contenant le lien pour télécharger le document demandé dans quelques minutes.",
      "selection-label": "Votre sélection",
      "selection-clear": "Changer la sélection",
      "mandatory": "Champs obligatoires",
      "label-salutation": "Titre",
      "option-salutation-mr": "M.",
      "option-salutation-mrs": "Mme",
      "label-firstname": "Prénom",
      "label-lastname": "Nom de famille",
      "label-email": "E-mail",
      "label-country": "Pays",
      "label-phone": "Téléphone",
      "label-newsletter": "Oui, je souhaite recevoir à l'avenir la newsletter de DILO Armaturen und Anlagen GmbH par e-mail. Je sais que je peux révoquer mon consentement à tout moment.",
      "label-privacy": "Je suis d'accord pour que DILO Armaturen und Anlagen GmbH traite mes données en toute confidentialité et les sauvegarde et les traite pour envoyer des informations sur les produits.",
      "placeholder-select": "Veuillez sélectionner",
      "button-label": "Demandez le fichier",
      "button-loading": "Demande en cours d'envoi",
      "success-text-1": "Transmission de données réuissie.",
      "success-text-2": "Vous recevrez un e-mail sous peu."
    }
  },
  "es": {
    "header": {
      "meta-title": "Instrucciones de empleo"
    },
    "footer": {
      "company-title": "DILO",
      "company-subtitle": "Armaturen und Anlagen GmbH",
      "company-street": "Frundsbergstrasse 36",
      "company-city": "D-87727 Babenhausen/Baviera",
      "tel-label": "Teléfono",
      "tel-number": "+49 (0) 83 33 - 302-0",
      "mail-label": "e-mail",
      "mail-address-account": "info",
      "mail-address-domain": "dilo.com",
      "imprint-label": "Aviso legal",
      "imprint-link": "https://dilo.eu/aviso legal",
      "terms-label": "CGC",
      "terms-link": "https://dilo.eu/cgc",
      "privacy-label": "Protección de datos ",
      "privacy-link": "https://dilo.eu/protección de datos",
      "web-label": "dilo.eu",
      "web-link": "https://dilo.eu",
      "copyright": "© DILO GmbH"
    },
    "app": {
      "intro-title": "Centro de descargas - Instrucciones de empleo",
      "intro-subtitle": "Encuentre las instrucciones de empleo para su producto DILO",
      "intro-text-1": "Gracias a nuestra búsqueda en línea podrá encontrar sus instrucciones de empleo de forma rápida y sencilla",
      "intro-text-2": "Haga clic en \"Solicitar\" junto al documento en cuestión. ",
      "contact-title": "Contacto",
      "contact-subtitle": "¿No encuentra su documento o tiene más preguntas?",
      "contact-text": "Póngase en contacto directamente con nosotros",
      "contact-button-label": "Contacto",
      "contact-button-link": "https://dilo.eu/es/kontakt/formulario-de-contacto"
    },
    "manualfinder": {
      "title-filter": "Filtro de búsqueda",
      "title-listing": "Instrucciones de empleo",
      "title-form": "Solicitar las instrucciones de empleo",
      "text-loading": "Cargar"
    },
    "filterresult": {
      "productarea-label": "Área de productos",
      "productgroup-label": "Grupo de productos",
      "productline-label": "Línea de productos",
      "model-label": "Serie",
      "resetfilter-label": "Restablecer el filtro de búsqueda"
    },
    "selectoption": {
      "count-label": "Entradas",
      "placeholder": "Por favor, seleccione",
      "placeholder-search": "Entrada para la búsqueda"
    },
    "resultlisting": {
      "title": "Entradas",
      "count-label": "Resultados",
      "table-heading-1": "Serie / Producto",
      "table-heading-2": "Versión",
      "table-heading-3": "Idioma",
      "table-heading-4": "Solicitud",
      "requestbutton-label": "Solicitud de descarga",
      "requestbutton-filerequest": "A petición",
      "requestbutton-missing": "No disponible",
      "noresult-text": "No hay documentos para su selección."
    },
    "requestform": {
      "intro-text-1": "Ya casi está. Sólo tiene que rellenar el formulario de solicitud y hacer clic en \"Solicitar documento\"",
      "intro-text-2": "Le enviaremos un correo electrónico con el enlace de descarga de su documento en unos minutos.",
      "selection-label": "Su selección",
      "selection-clear": "Cambiar la selección ",
      "mandatory": "Campo obligatorio",
      "label-salutation": "Título",
      "option-salutation-mr": "Sr.",
      "option-salutation-mrs": "Sra.",
      "label-firstname": "Nombre",
      "label-lastname": "Apellido",
      "label-email": "e-mail",
      "label-country": "País",
      "label-phone": "Teléfono",
      "label-newsletter": "Sí, me gustaría recibir el boletín de noticias de DILO Armaturen und Anlagen GmbH por correo electrónico en el futuro. Soy consciente de que puedo revocar mi consentimiento en cualquier momento.",
      "label-privacy": "Acepto que DILO Armaturen und Anlagen GmbH trate mis datos de forma confidencial y los almacene y procese de enviar información sobre los productos.",
      "placeholder-select": "Por favor, seleccione",
      "button-label": "Solicitar archivo",
      "button-loading": "Solicitud que se envía",
      "success-text-1": "Sus datos han sido transmitidos con éxito.",
      "success-text-2": "En breve recibirá un correo electrónico."
    }
  }
}
