<template>
    <div id="Manualfinder">
        <div id="step-1"></div>

        <div
            class="py-6 flex flex-wrap -mx-4 sm:mx-0 bg-dilo-gray-light bg-opacity-40"
        >
            <div class="px-6 w-full lg:w-4/12 xl:w-3/12 border-b-8 mb-8 pb-8 border-white lg:border-r-2 lg:border-b-0 lg:mb-0 lg:pb-0">
                <h3>{{ $t("manualfinder.title-filter") }}</h3>
                <FilterResult
                    v-if="appInitialized"
                    :visibleOptions="visibleOptionsObject"
                    @onSelectOptionChange="onSelectOptionChange($event)"
                    @clearSelections="clearSelections()"
                />
                <p v-else class="text-yellow-500 font-bold">
                    {{ $t("manualfinder.text-loading") }}...
                </p>
            </div>

            <div class="px-6 w-full lg:w-8/12 xl:w-9/12">
                <h3>{{ $t("manualfinder.title-listing") }}</h3>
                <ResultListing
                    v-if="appInitialized"
                    :filteredResult="resultArray"
                    @onRequestFile="requestFile($event)"
                />
                <p v-else class="text-yellow-500 font-bold">
                    {{ $t("manualfinder.text-loading") }}...
                </p>
            </div>
        </div>

        <div id="step-2"></div>

        <div
            class="py-6 px-6 mt-16 -mx-4 sm:mx-0 bg-dilo-gray-light bg-opacity-40"
            v-if="showForm"
        >
            <h3>{{ $t("manualfinder.title-form") }}</h3>
            <RequestForm
                :selectedEntry="selectedEntry"
                @onClearSelectedFile="clearSelectedFile()"
                :submitSuccessful="submitSuccessful"
                @submitSuccessful="submitSuccessful = $event"
            />
        </div>
    </div>
</template>

<script>
import { Database } from "../helper/database.js";
import { Remote } from "../helper/remote.js";

import FilterResult from "../components/FilterResult.vue";
import ResultListing from "../components/ResultListing.vue";
import RequestForm from "../components/RequestForm.vue";

export default {
    name: "Manualfinder",
    data() {
        return {
            remote: null,
            db: null,
            resultArray: [], // array that holds filtered items for the result listing
            visibleOptionsObject: {}, // object that holds visible options for select/dropdown elements
            selectedOptions: {}, // object that holds all user selected filters
            retrievingData: false,
            appInitialized: false,
            selectedEntry: {}, // holds the user selected result file
            submitSuccessful: false // submitted form
        };
    },
    computed: {
        loading() {
            return this.retrievingData || !this.appInitialized ? true : false;
        },
        showForm() {
            return Object.keys(this.selectedEntry).length > 0 ? true : false;
        },
        locale() {
            return this.$route.params.locale;
        }
    },
    methods: {
        async retrieveAreas(ids) {
            this.retrievingData = true;
            let areas = [];
            if (typeof ids === "undefined")
                areas = await this.db.retrieveAreas();
            else areas = await this.db.retrieveAreasById(ids);
            this.retrievingData = false;
            return areas;
        },
        async retrieveGroups(ids) {
            this.retrievingData = true;
            let groups = [];
            if (typeof ids === "undefined")
                groups = await this.db.retrieveGroups();
            else groups = await this.db.retrieveGroupsById(ids);
            this.retrievingData = false;
            return groups;
        },
        async retrieveLines(ids) {
            this.retrievingData = true;
            let lines = [];
            if (typeof ids === "undefined")
                lines = await this.db.retrieveLines();
            else lines = await this.db.retrieveLinesById(ids);
            this.retrievingData = false;
            return lines;
        },
        async retrieveModels(filter) {
            this.retrievingData = true;
            let models = [];
            if (typeof filter === "undefined")
                models = await this.db.retrieveModels();
            else {
                // filter by array with ids
                if (Array.isArray(filter))
                    models = await this.db.retrieveModelsById(filter);
                // filter by object
                else models = await this.db.retrieveModelsByObject(filter);
            }
            this.retrievingData = false;
            return models;
        },
        onSelectOptionChange(select) {
            // store new selection
            if (select.value === "") delete this.selectedOptions[select.id];
            else this.selectedOptions[select.id] = select.value;
        },
        clearSelections() {
            this.selectedOptions = {};
        },
        async filterModels() {
            // update result listing
            const filter = this.selectedOptions;

            if (Object.keys(filter).length > 0) {
                this.resultArray = await this.retrieveModels(filter);
            } else this.resultArray = await this.retrieveModels();
        },
        async filterDropdownOptions(modelListing) {
            // changes the visible and selectable options lists
            // grab ids that need to be visible by checking all visible results of listing
            const visibleOptionsIds = {
                // must be equal structure to this.visibleOptionsObject
                areas: [],
                groups: [],
                lines: [],
                models: []
            };
            modelListing.forEach(model => {
                model.productGroup.forEach(pg => {
                    // we might have multiple productgroups now (array)
                    visibleOptionsIds.areas.push(pg.id);
                })
                visibleOptionsIds.groups.push(model.modelGroup.id);
                visibleOptionsIds.lines.push(model.modelLine.id);
                visibleOptionsIds.models.push(model.id);
            });
            // remove duplicates
            Object.keys(visibleOptionsIds).forEach(key => {
                let property = visibleOptionsIds[key];
                visibleOptionsIds[key] = [...new Set(property)];
            });

            // collect entries that need to be displayed
            const visibleOptions = {
                // must be equal structure to this.optionsObject
                areas: [],
                groups: [],
                lines: [],
                models: []
            };
            visibleOptions.areas = await this.retrieveAreas(
                visibleOptionsIds.areas
            );
            visibleOptions.groups = await this.retrieveGroups(
                visibleOptionsIds.groups
            );
            visibleOptions.lines = await this.retrieveLines(
                visibleOptionsIds.lines
            );
            visibleOptions.models = await this.retrieveModels(
                visibleOptionsIds.models
            );

            this.visibleOptionsObject = visibleOptions;
        },
        requestFile(selectedEntry) {
            this.submitSuccessful = false;
            this.selectedEntry = selectedEntry;

            setTimeout(() => {
                document
                    .getElementById("step-2")
                    .scrollIntoView({ behavior: "smooth" });
            }, 100);
        },
        resetState() {
            this.remote = null;
            this.db = null;
            this.resultArray = [];
            this.selectedOptions = {};
            this.retrievingData = false;
            this.appInitialized = false;
            this.selectedEntry = {};
            this.submitSuccessful = false;
        },
        async setupStore() {
            this.resetState();

            // init remote pimcore connection
            this.remote = new Remote(this.$i18n.locale);

            // fetch remote data
            const areas = await this.remote.fetchAreas();
            const groups = await this.remote.fetchGroups(); // {"data":{"getModelGroupListing":{"edges":[{"node":{"id":"28","name":"Serviceger\u00e4te SF6"}},{"node":{"id":"29","name":"Messger\u00e4te SF6"}}]}}}
            const lines = await this.remote.fetchLines(); // {"data":{"getModelLineListing":{"edges":[{"node":{"id":"23","name":"Micro-Serie"}},{"node":{"id":"26","name":"Mini-Serie"}},{"node":{"id":"30","name":"MirrorAnalyser"}}]}}}
            const models = await this.remote.fetchModels(); // {"data":{"getModelListing":{"edges":[{"node":{"id":"24","name":"B160R92","modificationDate":1619424603,"modelGroup":{"id":"28","name":"Serviceger\u00e4te SF6"},"modelLine":{"id":"23","name":"Micro-Serie"},"operatingManuals":[{"lang":"de_DE","operatingManual":{"id":"20","filename":"OM_B160R92_DE.pdf"}},{"lang":"en_EN","operatingManual":{"id":"19","filename":"OM_B160R92_EN.pdf"}}]}},{"node":{"id":"25","name":"B160R11","modificationDate":1619424607,"modelGroup":{"id":"28","name":"Serviceger\u00e4te SF6"},"modelLine":{"id":"23","name":"Micro-Serie"},"operatingManuals":[{"lang":"de_DE","operatingManual":{"id":"20","filename":"OM_B160R92_DE.pdf"}}]}},{"node":{"id":"27","name":"B143R11","modificationDate":1619424597,"modelGroup":{"id":"28","name":"Serviceger\u00e4te SF6"},"modelLine":{"id":"26","name":"Mini-Serie"},"operatingManuals":[{"lang":"de_DE","operatingManual":{"id":"20","filename":"OM_B160R92_DE.pdf"}},{"lang":"en_EN","operatingManual":{"id":"19","filename":"OM_B160R92_EN.pdf"}}]}},{"node":{"id":"31","name":"3-035R-R...","modificationDate":1619512689,"modelGroup":{"id":"29","name":"Messger\u00e4te SF6"},"modelLine":{"id":"30","name":"MirrorAnalyser"},"operatingManuals":null}}]}}}

            // init database
            this.db = new Database();

            // clear existing data
            this.db.clearTables();

            // store remote data locally
            await this.db.storeAreas(areas);
            await this.db.storeGroups(groups);
            await this.db.storeLines(lines);
            await this.db.storeModels(models);

            // populate result listing
            this.resultArray = await this.retrieveModels();

            // prepare visible options, those will be populated via resultArray watcher
            this.visibleOptionsObject = {
                areas: [],
                groups: [],
                lines: [],
                models: []
            };

            // remove loading spinner
            this.appInitialized = true;
        },
        clearSelectedFile() {
            this.selectedEntry = {};

            setTimeout(() => {
                document
                    .getElementById("step-1")
                    .scrollIntoView({ behavior: "smooth" });
            }, 100);
        }
    },
    watch: {
        locale(newVal) {
            // set language
            this.$i18n.locale = newVal;
            document
                .querySelector("html")
                .setAttribute("lang", this.$i18n.locale);

            document.title = this.$t("header.meta-title") + " | Dilo";

            // re-init app
            this.setupStore();
        },
        selectedOptions: {
            handler() {
                if (!this.appInitialized) return;

                // filter result because selection changed
                this.filterModels();
            },
            deep: true
        },
        async resultArray(newVal) {
            if (!this.appInitialized) return;

            // filter dropdown options because result list changed
            this.filterDropdownOptions(newVal);
        }
    },
    created() {
        // init app
        this.setupStore();
    },
    mounted() {
        document.title = this.$t("header.meta-title") + " | Dilo";
    },
    components: {
        FilterResult,
        ResultListing,
        RequestForm
    }
};
</script>

<style scoped>
h3 {
    font-size: 1.3125rem;
    line-height: 1.38;
    font-weight: bold;
}
</style>
