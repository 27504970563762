import { createApp } from "vue" // vue
import { createI18n } from "vue-i18n" // i18n plugin
import { createRouter, createWebHistory } from "vue-router" // router plugin
import VueNextSelect from "vue-next-select" // select plugin
import "../node_modules/vue-next-select/dist/index.min.css"

import App from "./App.vue"
import Manualfinder from "./pages/Manualfinder.vue"

import "./index.css" // tailwindcss
import translation from "./localization/i18n.js" // translation

// create and setup i18n
const i18n = createI18n({
  locale: "de", // default lang
  fallbackLocale: "en",
  messages: translation,
})

// create and setup router
const routes = [
  { path: "/", redirect: "/de" }, // default route if no lang provided
  {
    path: "/:locale",
    component: Manualfinder,
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      const supported = ["de", "en", "fr", "es"]
      if (!supported.includes(locale)) return next("de") // default route if wrong lang provided

      i18n.global.locale = locale // overwrite default lang by route
      document.querySelector("html").setAttribute("lang", locale)

      return next()
    },
  },
  // { path: '/:pathMatch(.*)*', name: 'not-found', component: NotFound },
]
const router = createRouter({
  history: createWebHistory("/"),
  // history: createWebHashHistory(), // # routing
  routes,
})

// create and setup vue root instance
const app = createApp(App)

// use plugins
app.use(i18n)
app.use(router)
app.component("vue-select", VueNextSelect)

// mount
app.mount("#app")

// allow cors
// /home/pimcoredilo/public_html/dilo/application/vendor/pimcore/data-hub/src/Controller/WebserviceController.php
// Header add Access-Control-Allow-Origin "*"
// Header add Access-Control-Allow-Headers "Origin, Content-Type, X-Auth-Token"
// Header add Access-Control-Allow-Methods "GET, POST, PUT, DELETE, PATCH, OPTIONS;
