<template>
    <div id="LocaleChanger">
        <select @change="switchToLang($event)" class="shadow-md py-2 pl-1">
            <option :value="activeLang" selected>
                {{ activeLang.toUpperCase() }}
            </option>
            <option v-for="lang in inactiveLangs" :key="lang" :value="lang">
                {{ lang.toUpperCase() }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "LocaleChanger",
    data() {
        return {};
    },
    computed: {
        activeLang() {
            return this.$i18n.locale
        },
        inactiveLangs() {
            const langs = ["de", "en", "fr", "es"];
            return langs.filter((lang) => {
                return (lang === this.activeLang) ? false : true;
            });
        }
    },
    methods: {
        switchToLang(event) {
            this.$router.push("/" + event.target.value);
        }
    }
};
</script>
