<template>
    <div id="SelectOption">
        <h2 class="leading-none mb-2 flex flex-wrap items-end justify-between">
            <b class="mr-4">{{ name }}</b>
            <small class="text-sm">
                {{ visibleOptions.length }}
                {{ $t("selectoption.count-label") }}
            </small>
        </h2>

        <div
            class="flex items-center relative"
            :class="{
                'has-selection': selectionMade
            }"
        >
            <vue-select
                v-model="selectedOption"
                :options="visibleOptions"
                :empty-model-value="''"
                :loading="false"
                :close-on-select="true"
                :clear-on-select="true"
                :clear-on-close="true"
                :searchable="true"
                label-by="name"
                value-by="id"
                :placeholder="'- ' + $t('selectoption.placeholder') + ' -'"
                :search-placeholder="
                    $t('selectoption.placeholder-search') + '...'
                "
            ></vue-select>

            <div
                class="bg-dilo-white border-l text-dilo-gray border-dilo-gray-light absolute top-1 bottom-1 right-1 w-12 flex items-center justify-center cursor-pointer"
                :class="{
                    'pointer-events-none': !selectionMade
                }"
                @click="selectedOption = ''"
            >
                <svg
                    v-if="!selectionMade"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                </svg>
                <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectOption",
    props: ["name", "id", "visibleOptions", "resetSelectionOnce", "preselectedOptionId"],
    emits: ["onSelectOptionChange", "resetSelectionOnceDone"],
    data() {
        return {
            selectedOption: "",
            initiallySelectedAnOptionViaParameter: false
        };
    },
    computed: {
        noOptions() {
            return this.visibleOptions.length === 0;
        },
        selectionMade() {
            return this.selectedOption !== "";
        }
    },
    created() {
    },
    watch: {
        // visible options change when get request settled or when options filterd
        visibleOptions() {
            // preselect an option if provided
            if (this.preselectedOptionId && !this.initiallySelectedAnOptionViaParameter) {
                const idIsValid = this.visibleOptions.find((opts) => opts.id === this.preselectedOptionId)
                if (typeof idIsValid !== 'undefined') {
                    this.selectedOption = this.preselectedOptionId
                    this.initiallySelectedAnOptionViaParameter = true
                }
            }

            // reset selection on options list change
            if (this.resetSelectionOnce) {
                this.selectedOption = ""
                this.$emit("resetSelectionOnceDone")
            }
        },
        selectedOption(value) {
            this.$emit("onSelectOptionChange", {
                id: this.id,
                value: parseInt(value) || ""
            });
        }
    }
};
</script>

<style>
.vue-select {
    @apply bg-white border-2 border-white rounded-none w-full shadow-md;
}

.has-selection .vue-select {
    @apply border-dilo-gray-dark;
}

.vue-select .vue-input {
    @apply h-12 pl-4 pr-8;
}

.vue-select input {
    @apply text-lg cursor-text !important;
}

.has-selection .vue-select ::placeholder {
    @apply text-dilo-black;
}

.vue-select .icon.arrow-downward {
    border-width: 0.375rem 0.375rem 0;
    margin-right: 1rem;
}

.vue-select .highlighted {
    @apply bg-dilo-gray-light;
}

.vue-select .selected,
.vue-select .selected.highlighted {
    @apply bg-dilo-gray-dark relative;
}

.vue-select .vue-dropdown {
    @apply shadow-sm border-dilo-gray-light mt-2 rounded-none;
}

.vue-select .vue-dropdown .vue-dropdown-item {
    @apply h-12 px-4 flex items-center leading-none;
}
</style>
