import Dexie from "dexie"

// "Database" inherits from the Dexie class to handle all database logic
export class Database extends Dexie {
  constructor() {
    // init local database
    super("Store") // new Dexie("Store");

    // define db schema
    this.version(5).stores({
      areas: "++id",
      groups: "++id",
      lines: "++id",
      models: "++id",
    })

    // we can now write code such as "this.todos.add(...)"
    // rather than "this.table('todos').add(...)"
    this.areas = this.table("areas")
    this.groups = this.table("groups")
    this.lines = this.table("lines")
    this.models = this.table("models")
  }

  // clear all tables
  async clearTables() {
    this.areas.clear()
    this.groups.clear()
    this.lines.clear()
    this.models.clear()
  }

  // retrieves all areas from the areas object store
  async retrieveAreas() {
    return await this.areas.toArray()
  }

  async retrieveAreasById(ids) {
    return await this.areas
      .where("id")
      .anyOf(ids)
      .toArray()
  }

  // retrieves all groups from the groups object store
  async retrieveGroups() {
    return await this.groups.toArray()
  }

  async retrieveGroupsById(ids) {
    return await this.groups
      .where("id")
      .anyOf(ids)
      .toArray()
  }

  // retrieves all lines from the lines object store
  async retrieveLines() {
    return await this.lines.toArray()
  }

  async retrieveLinesById(ids) {
    return await this.lines
      .where("id")
      .anyOf(ids)
      .toArray()
  }

  // retrieves all models from the models object store
  async retrieveModels() {
    return await this.models.toArray()
  }

  async retrieveModelsById(ids) {
    return await this.models
      .where("id")
      .anyOf(ids)
      .toArray()
  }

  async retrieveModelsByObject(object) {
    // filter models by ID via provided object that holds all selected options
    const query = {}

    // prepare query
    Object.keys(object).forEach((selectId) => {
      switch (selectId) {
        case "area": // id of select el. in this case baureihe dropdown. filter by id in database
          query["productGroup.id"] = object[selectId].toString()
          break
        case "model":
          query["id"] = object[selectId].toString() // { "id": "24" }
          break
        case "productline":
          query["modelLine.id"] = object[selectId].toString() // { "modelLine": { "id": "24" } }
          break
        case "productgroup":
          query["modelGroup.id"] = object[selectId].toString() // { "modelGroup": { "id": "24" } }
          break
      }
    })

    // do the magic filtering
    return await this.models.filter(m => {
      const area =
        (typeof query["productGroup.id"] === 'undefined') ||
          m.productGroup.some(pg => pg.id === query["productGroup.id"])
      const model =
        (typeof query["id"] === 'undefined') ||
          m.id === query["id"]
      const productline =
        (typeof query["modelLine.id"] === 'undefined') ||
          m.modelLine.id === query["modelLine.id"]
      const productgroup =
        (typeof query["modelGroup.id"] === 'undefined') ||
          m.modelGroup.id === query["modelGroup.id"]

      return area && model && productline && productgroup
    }).toArray()
  }

  // write areas to store
  async storeAreas(areas) {
    await this.areas.bulkPut(areas)
  }

  // write groups to store
  async storeGroups(groups) {
    await this.groups.bulkPut(groups)
  }

  // write lines to store
  async storeLines(lines) {
    await this.lines.bulkPut(lines)
  }

  // write models to store
  async storeModels(models) {
    await this.models.bulkPut(models)
  }
}
