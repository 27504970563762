<template>
    <div id="App" class="App">
        <AppHeader class="mb-12 lg:mb-24" />

        <div class="container my-12">
            <h1 class="text-2xl md:text-4xl uppercase mb-1 font-bold">
                {{ $t("app.intro-title") }}
            </h1>
            <h2 class="text-xl md:text-2xl mb-4">
                {{ $t("app.intro-subtitle") }}
            </h2>
            <p>
                {{ $t("app.intro-text-1") }}
                <br />
                {{ $t("app.intro-text-2") }}
            </p>
        </div>

        <router-view class="container my-12"></router-view>

        <div class="contact container my-24">
            <div class="h-48 relative">
                <img
                    src="./assets/images/background-contact.jpg"
                    alt="Background Contact"
                    class="w-full h-full object-cover object-right"
                />
                <h2
                    class="absolute font-bold left-6 bottom-4 uppercase text-4xl text-dilo-white"
                >
                    {{ $t("app.contact-title") }}
                </h2>
            </div>
            <div class="py-5 px-6 bg-dilo-gray-light bg-opacity-40">
                <h3 class="text-2xl mb-3">
                    {{ $t("app.contact-subtitle") }}
                </h3>
                <p class="mb-3">{{ $t("app.contact-text") }}</p>

                <AppButton
                    :href="$t('app.contact-button-link')"
                    target="_blank"
                >
                    {{ $t("app.contact-button-label") }}
                </AppButton>
            </div>
            <img
                src="./assets/icons/contact-us.svg"
                alt="Contact Us"
                width="160"
                height="160"
            />
        </div>

        <AppFooter class="mt-24" />
    </div>
</template>

<script>
import AppHeader from "./components/template/AppHeader.vue";
import AppFooter from "./components/template/AppFooter.vue";
import AppButton from "./components/template/AppButton.vue";

export default {
    name: "App",
    components: {
        AppHeader,
        AppFooter,
        AppButton
    }
};
</script>

<style scoped>
.contact {
    position: relative;
}

@screen md {
    .contact > div + div {
        padding-right: 21rem;
    }
}

.contact > img {
    position: absolute;
    right: 6rem;
    top: 50%;
    transform: translateY(-50%);
    display: none;
}

@screen md {
    .contact > img {
        display: block;
    }
}
</style>
